"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DD = void 0;
const Global_1 = require("./Global");
const Util_1 = require("./Util");
exports.DD = {
  get isDragging() {
    var flag = false;
    exports.DD._dragElements.forEach(elem => {
      if (elem.dragStatus === 'dragging') {
        flag = true;
      }
    });
    return flag;
  },
  justDragged: false,
  get node() {
    var node;
    exports.DD._dragElements.forEach(elem => {
      node = elem.node;
    });
    return node;
  },
  _dragElements: new Map(),
  _drag(evt) {
    const nodesToFireEvents = [];
    exports.DD._dragElements.forEach((elem, key) => {
      const {
        node
      } = elem;
      const stage = node.getStage();
      stage.setPointersPositions(evt);
      if (elem.pointerId === undefined) {
        elem.pointerId = Util_1.Util._getFirstPointerId(evt);
      }
      const pos = stage._changedPointerPositions.find(pos => pos.id === elem.pointerId);
      if (!pos) {
        return;
      }
      if (elem.dragStatus !== 'dragging') {
        var dragDistance = node.dragDistance();
        var distance = Math.max(Math.abs(pos.x - elem.startPointerPos.x), Math.abs(pos.y - elem.startPointerPos.y));
        if (distance < dragDistance) {
          return;
        }
        node.startDrag({
          evt
        });
        if (!node.isDragging()) {
          return;
        }
      }
      node._setDragPosition(evt, elem);
      nodesToFireEvents.push(node);
    });
    nodesToFireEvents.forEach(node => {
      node.fire('dragmove', {
        type: 'dragmove',
        target: node,
        evt: evt
      }, true);
    });
  },
  _endDragBefore(evt) {
    const drawNodes = [];
    exports.DD._dragElements.forEach(elem => {
      const {
        node
      } = elem;
      const stage = node.getStage();
      if (evt) {
        stage.setPointersPositions(evt);
      }
      const pos = stage._changedPointerPositions.find(pos => pos.id === elem.pointerId);
      if (!pos) {
        return;
      }
      if (elem.dragStatus === 'dragging' || elem.dragStatus === 'stopped') {
        exports.DD.justDragged = true;
        Global_1.Konva._mouseListenClick = false;
        Global_1.Konva._touchListenClick = false;
        Global_1.Konva._pointerListenClick = false;
        elem.dragStatus = 'stopped';
      }
      const drawNode = elem.node.getLayer() || elem.node instanceof Global_1.Konva['Stage'] && elem.node;
      if (drawNode && drawNodes.indexOf(drawNode) === -1) {
        drawNodes.push(drawNode);
      }
    });
    drawNodes.forEach(drawNode => {
      drawNode.draw();
    });
  },
  _endDragAfter(evt) {
    exports.DD._dragElements.forEach((elem, key) => {
      if (elem.dragStatus === 'stopped') {
        elem.node.fire('dragend', {
          type: 'dragend',
          target: elem.node,
          evt: evt
        }, true);
      }
      if (elem.dragStatus !== 'dragging') {
        exports.DD._dragElements.delete(key);
      }
    });
  }
};
if (Global_1.Konva.isBrowser) {
  window.addEventListener('mouseup', exports.DD._endDragBefore, true);
  window.addEventListener('touchend', exports.DD._endDragBefore, true);
  window.addEventListener('mousemove', exports.DD._drag);
  window.addEventListener('touchmove', exports.DD._drag);
  window.addEventListener('mouseup', exports.DD._endDragAfter, false);
  window.addEventListener('touchend', exports.DD._endDragAfter, false);
}